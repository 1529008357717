<template>
  <div class="body">
    <div class="pay-record">
      <div class="pay-record-item">
        <span class="pay-record-item-label">就诊人姓名：</span
        >{{ recordDetail.hisPatientName }}
      </div>
      <div class="pay-record-item">
        <span class="pay-record-item-label">就诊流水号：</span
        >{{ recordDetail.clinicSeq }}
      </div>
      <div
        class="pay-record-item"
        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
      >
        <span class="pay-record-item-label">接诊科室：</span
        ><span>{{ recordDetail.bizDeptName }}</span>
      </div>
      <div class="pay-record-item">
        <span class="pay-record-item-label">自费金额：</span
        ><span style="color: #ee0a24">￥{{ recordDetail.payFee / 100 }}</span>
      </div>
      <div class="pay-record-item">
        <span class="pay-record-item-label">总金额：</span
        ><span style="color: #ee0a24"
          >￥{{ recordDetail.totalPayFee / 100 }}</span
        >
      </div>
    </div>
    <div class="pay-record-details">
      <van-collapse v-model="activeNames">
        <van-collapse-item
          v-for="(v, i) in recordDetail.items"
          :key="v.bizItemId"
          :name="i"
          :title="v.bizItemType"
          :value="'￥' + v.bizItemFee / 100"
        >
          <div>缴费明细项</div>
          <div
            v-for="(item, index) in v.details"
            :key="index"
            class="pay-record-details"
          >
            <div class="pay-record-item">编码:{{ item.detailCode }}</div>
            <div class="pay-record-item">名称:{{ item.detailName }}</div>
            <div class="pay-record-item">规格:{{ item.detailSpace }}</div>
            <div class="pay-record-item">数量:{{ item.detailNum }}</div>
            <div class="pay-record-item">单位:{{ item.detailUnits }}</div>
            <div class="pay-record-item">单价:{{ item.detailPrice }}</div>
            <div class="pay-record-item">金额:{{ item.detailFee }}</div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "paymentListEntry",
  data() {
    return {
      recordDetail: {},
      activeNames: [],
    };
  },
  methods: {
    fetchEH2003() {
      let params = {
        clinicSeq: this.$route.query.clinicSeq,
        patid: this.$route.query.patid,
      };
      this.$http.get("/api/clinic/eh2003", params).then((result) => {
        this.recordDetail = result.data.data;
      });
      // this.recordDetail = {
      //   clinicSeq: "01545564-98",
      //   hisPatientId: 1,
      //   hisPatientName: "黄爽",
      //   cardNo: 1,
      //   bizItemIds: 1,
      //   bizDeptCode: 1,
      //   bizDeptName: "耳鼻喉科",
      //   bizDoctorId: 1,
      //   bizDoctorName: "张三",
      //   payFee: 1500,
      //   recPayFee: 1500,
      //   totalPayFee: 1500,
      //   bizItemTypeFee: 1,
      //   items: [
      //     {
      //       bizItemId: 1,
      //       bizItemType: "非处方",
      //       bizItemFee: 500,
      //       details: [
      //         {
      //           detailCode: "10001",
      //           detailName: "氯化钠溶液",
      //           detailSpace: "500g/ml",
      //           detailNum: 1,
      //           detailUnits: "剂",
      //           detailPrice: 1500,
      //           detailFee: 1500,
      //         },
      //         {
      //           detailCode: "10001",
      //           detailName: "氯化钠溶液",
      //           detailSpace: "500g/ml",
      //           detailNum: 1,
      //           detailUnits: "剂",
      //           detailPrice: 1500,
      //           detailFee: 1500,
      //         },
      //       ],
      //     },
      //     {
      //       bizItemId: 1,
      //       bizItemType: "非处方",
      //       bizItemFee: 500,
      //       details: [
      //         {
      //           detailCode: "10001",
      //           detailName: "氯化钠溶液",
      //           detailSpace: "500g/ml",
      //           detailNum: 1,
      //           detailUnits: "剂",
      //           detailPrice: 1500,
      //           detailFee: 1500,
      //         },
      //         {
      //           detailCode: "10001",
      //           detailName: "氯化钠溶液",
      //           detailSpace: "500g/ml",
      //           detailNum: 1,
      //           detailUnits: "剂",
      //           detailPrice: 1500,
      //           detailFee: 1500,
      //         },
      //       ],
      //     },
      //   ],
      // };
    },
  },
  mounted() {
    this.fetchEH2003();
  },
};
</script>

<style scoped>
.pay-record {
  width: 9.5rem;
  background-color: #ffff;
  border-radius: 10px;
  margin: 20px auto;
  margin-top: 60px;
  padding: 5px;
}
.pay-record-details {
  width: 9.5rem;
  background-color: #ffff;
  border-radius: 10px;
  margin: 5px auto;
  overflow: hidden;
}
.pay-record-item {
  font-size: 0.4rem;
  line-height: 26px;
  text-align: left;
}
.pay-record-item-label {
  width: 2.8rem;
  display: inline-block;
  text-align: right;
  margin-right: 5px;
}

/deep/ .van-cell__title {
  text-align: left;
}
</style>
